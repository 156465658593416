export enum ProdutosComissoes {
	BeneficiosNegociados = 0,
	ProdutosTitular = 1,
	ProdutosTitularEDependente = 2,
	ProdutosDependente = 3,
	DefinirPAFTitularEDependenteDiferentes = 4,
	DefinirPAFTitularEDependentesIguais = 5,
	DefinirPAFTitular = 6,
	ComissoesTitularOuTitularDependenteDiferentes = 7,
	ComissoesDependente = 8,
	EnvioClausulaConvencaoColetiva = 9
}

export enum IS_ADM {
	IS_NOT_ADM = 0,
	IS_ADM = 1
}

export enum EnumTipoOperacao {
	CREATE = 'C',
	READ = 'R',
	UPDATE = 'U',
	DELETE = 'D'
}

export enum TipoProvedor {
	CLIN = 'CLIN',
	AGIBEN = 'AGIBEN',
	MELHORAI = 'MELHORAI'
}

export enum PosicaoEfeito {
	DIREITA = 'dir',
	ESQUERDA = 'esq',
	CENTRAL = 'central'
}

export enum TipoAcordoCCTEnum {
	CCT = 'CCT',
	ACT = 'ACT'
}

export enum StepCCTContratosAssinados {
	INITIAL_STEP,
	FINAL_STEP
}

export enum Titularidade {
	Titular = 'Titular',
	Dependente = 'Dependente'
}

export enum TipoIntermediador {
	TITULAR = 'TITULAR',
	DEPENDENTE = 'DEPENDENTE'
}

export enum TipoValidoOuInvalido {
	VALID = 'VALID',
	INVALID = 'INVALID'
}

export enum EnumStatusFiltroCCTACT {
	ATIVO = 'ATIVO',
	INATIVO = 'INATIVO',
	AGUARDANDO_PARAMETRIZACAO = 'AGUARDANDO_PARAMETRIZACAO',
	IMPLANTADO = 'IMPLANTADO'
}

export enum ModoAtivoParametrizacaoCCT {
	CRIACAO = 'CRIACAO',
	EDICAO = 'EDICAO',
	VISUALIZACAO = 'VISUALIZACAO'
}

export enum AbasGestaoCarteira {
	EMPRESA = 0,
	USUARIO = 1
}

export enum TipoTitularInclusaoVidas {
	TITULAR = '1',
	DEPENDENTE = '2'
}

export enum GrauParentesco {
	TITULAR = '1',
	CONJUGE_OU_COMPANHEIRO = '3',
	FILHO = '4',
	ENTEADO = '6',
	PAI_OU_MAE = '8',
	AGREGADOS_OU_OUTROS = '10'
}

export enum Sexo {
	MASCULINO = '1',
	FEMININO = '0'
}

export enum ClienteCompromissoStatus {
	'I' = 'Implantada',
	'C' = 'Em cadastro',
	'M' = 'Em migração'
}

export enum EnumBeneficios {
	ODONTO = 1,
	TELEMEDICINA = 2,
	ASSISTENCIA = 3,
	SEGURO_DE_VIDA = 4
}

export enum ProdutoCCTEnum {
	CLIN_CLASS = 1,
	CLIN_SMILE = 2,
	CLIN_SMART = 3,
	ORTOCLIN_PLUS = 4,
	MELHORAI_CONFORT = 6,
	MELHORAI_EXPRESS = 5,
	MELHORAI_QUALITY = 7,
	AUXILIO_NATALIDADE = 9,
	AUXILIO_FUNERAL = 10,
	SEGURO_DE_VIDA = 8
}

export enum SimOuNao {
	SIM = 'Sim',
	NAO = 'Não'
}
export enum VidasStatus {
	'T' = 'Titular',
	'D' = 'Dependetes'
}

export enum VidasFarmStatus {
	IMPLANTADO = 'vidas.status.implantada',
	EM_CADASTRO = 'vidas.status.em_cadastro',
	EM_MIGRACAO = 'vidas.status.em_migracao',
	TODOS = 'vidas.status.todos'
}

export enum CompromissoStatusEnum {
	Planejada = '1',
	Trabalhando = '2',
	Finalizada = '4',
	Cancelado = '5',
	Ausencia = '6'
}

export enum CompromissoTipoEnum {
	VISITA_ESPONTANEA = '1',
	VISITA_MARCADA = '2',
	VISITA_DE_MANUTENCAO = '3',
	VISITA_AVULSA = '4',
	VIDA_AVULSA = '5'
}
export enum VisaoEnum {
	Dia = 'dia',
	Semana = 'semana',
	Mes = 'mes'
}

export enum Grupos {
	INFLUENCIADOR = 25,
	HEAD = 27,
	SINDICATO_LABORAL = 29,
	SINDICATO_PATRONAL = 31,
	BACKOFFICE_AGIBEN = 32,
	MASTER = 14,
	CONSULTOR_AGIBEN = 30,
	INSIDE_SALES = 33,
	ADMINISTRADOR = 15,
	PLATAFORMA = 24,
	FIELD_SALES = 37,
	SALES_REP = 35,
	SALES_FARM = 34,
	CORRETOR = 26,
	SUPERVISOR_PLATAFORMA = 23,
	ADMINISTRADOR_SALES = 38,
	FIELD_SALES_MASSIFICADO = 39,
	ACCOUNT_EXECUTIVE = 36,
	ATENDIMENTO = 40
}

export enum Meses {
	'JANEIRO',
	'FEVEREIRO',
	'MARÇO',
	'ABRIL',
	'MAIO',
	'JUNHO',
	'JULHO',
	'AGOSTO',
	'SETEMBRO',
	'OUTUBRO',
	'NOVEMBRO',
	'DEZEMBRO'
}

export enum TipoContatoS4ELabel {
	CELULAR = 'Celular',
	EMAIL = 'Email',
	FIXO = 'Fixo'
}

export enum TipoContatoS4E {
	CELULAR = 8,
	EMAIL = 50,
	FIXO = 1
}

export enum StatusEmpresaS4E {
	A = 'A',
	ATIVO = 'ATIVO',
	I = 'I',
	INADIMPLENTE = 'INADIMPLENTE',
	CANCELADO = 'CANCELADO',
	BLOQUEADO = 'BLOQUEADO',
	SUSPENSO_PARA_CANCELAMENTO = 'SUSPENSO PARA CANCELAMENTO',
	SUSPENSO_PARA_ATIVAÇÃO = 'SUSPENSO PARA ATIVAÇÃO',
	REJEITADO = 'REJEITADO',
	PRE_CADASTRO = 'PRÉ - CADASTRO',
	ACORDO = 'ACORDO',
	CANCELAMENTO_PROGRAMADO = 'CANCELAMENTO PROGRAMADO',
	EM_ANALISE = 'EM ANÁLISE',
	EMPRESA_EM_ABERTA = 'EMPRESA EM ABERTA',
	POS_VENDA = 'PÓS-VENDA',
	FALENCIA = 'FALÊNCIA',
	INADIMP_CARTAO = 'INADIMP CARTAO',
	SITE_AGUARDANDO_PAGAMENTO = '(SITE)AGUARDANDO PAGAMENTO',
	JURIDICO = 'JURIDICO',
	FINALIZADO = 'FINALIZADO',
	AGUARDANDO_CANCELAMENTO = 'AGUARDANDO CANCELAMENTO',
	PLANO_CONTINUIDADE = 'PLANO CONTINUIDADE',
	INADIMP_CARTAO_ANTIGO = 'INADIMP CARTAO ANTIGO',
	Bloqueado_Ativo = 'Bloqueado Ativo',
	ATIVO_NO_DR_SORRIR = 'ATIVO NO DR SORRIR'
}

export enum Platforms {
	PLATAFORMA_DE_VENDAS = 'Plataforma de Vendas',
	RH = 'Plataforma RH',
	API_SAUDE = 'Api.Saúde',
	CLIN = 'Clin',
	CLINAPP = 'Clin app',
	MERCANTIL = 'Mercantil'
}
